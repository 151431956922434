import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flipping',
  templateUrl: './flipping.component.html',
  styleUrls: ['./flipping.component.scss']
})
export class FlippingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
